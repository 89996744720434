

import { Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './components/layout.css';
import "nes.css/css/nes.min.css";

import Game from "./routes/game"
import Home from "./routes/home"




function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/:id" element={<Home />} />
      <Route path="/notice/:id" element={<Home />} />
      <Route path="/Game/:id" element={<Game />} />
    </Routes>
      
  );
}

export default App;
